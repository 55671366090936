import * as React from "react"
import ShowNewClaimedNft from "../../components/show-newclaimed-nft";
import Layout from "../../components/layout"
import Seo from "../../components/seo"
const  forgottenLandscape = ( props ) => {

  const address = props.params.address; 

  return (
      <Layout>
        <section className='section-title white collection artwork'>
          <Seo title="Playground - RAW" />
          <div className='container clearfix flex'>
              <ShowNewClaimedNft tokenId={props.params.address} collectionName="RAW" imageUrl="https://stephan.0kai-cdn.com/image/1/#token_id/1200/800.png" byArtist="Stephan Vasement" collectionUrl="/raw/" />
          </div>
        </section>
      </Layout>
  );
}

export default forgottenLandscape
