import * as React from "react"
import { useEffect, useState } from "react";
import { Link } from "gatsby";
import loadingGif from "../images/loading.gif";

const ShowNewClaimedNft: React.FC = (props) => {
    const [toRender, setToRender] = useState(false);
    const [collectionImageUrl, setCollectionImageUrl] = useState();
    const { imageUrl, collectionName, tokenId, byArtist, collectionUrl, imageName } = props;
    useEffect(() => {
        setToRender(true);
        setCollectionImageUrl(getImageUrl());

        const initialDelay = setTimeout(() => {
            setToRender(false);
            setCollectionImageUrl(getImageUrl());
            setToRender(true);
        }, 30000); // 30000 ms = 30 seconds

        // Cleanup timeout on unmount
        return () => clearTimeout(initialDelay);

    }, []);

    function getImageUrl() {
        return imageUrl.replace("#token_id", tokenId);
    }

    return (
        <>
            <div className='col30 left artwork-info'>
                <h1 style={{textTransform:"capitalize"}}>{imageName?imageName:(`${collectionName} #${tokenId}`)}<br /><span>by {byArtist}</span></h1>
                <div className='clearfix tags'>
                    <Link to={collectionUrl} className='button black left'>back to gallery</Link>
                </div>
            </div>
            <div className='col70 left image populus'>
                {
                    toRender
                        ? (<img src={getImageUrl()}  style={{maxWidth:'90%'}} />)
                        : (<img alt="Loading" className="loading" src={loadingGif} />)
                }
                <br />
                <a href={ getImageUrl()} target='_blank' className='button transparent expand'>view high resolution</a>
            </div>
        </>
    );

};

export default ShowNewClaimedNft;